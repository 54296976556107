import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em !important;
  }

  @media only screen and (max-width: 1119px) {
    padding: 2em 1em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  @media only screen and (min-width: 1200px) {
    padding: 0em 2em !important;
  }
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero
      title="HOMEPAGE"
      dimmed
      img="https://res.cloudinary.com/mystay-international/image/upload/c_crop,dn_110,g_north,h_300,o_100,w_1400,y_317/v1555556131/Host%20Family%20English/iStock-505830388_high-1.jpg"
    />
    <Grid
      stackable
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <GridRow columns="2">
        <Grid.Column largeScreen="11">
          <Header as="h2">Welcome to Host Family English</Header>
          <Header as="h3">A World First</Header>
          <p>
            Host Family English (HFE) is the World’s first globally structured
            homestay host support program for Education Providers, Homestay
            Organizations and Education Agents to fast track their international
            students’ English results.
          </p>
          <Header as="h3">Building Your Business</Header>
          <p>
            We partner with select businesses to offer effective English
            immersion for international students and help build better business
            outcomes for our partners through much better results for their
            students and their homestay experience.
          </p>
          <Header as="h3">Full Training Package</Header>
          <p>
            Learning English should not stop when the student leaves the
            classroom. Host Family English ensures that a visiting student is
            maximizing their time while they are abroad to achieve their goals.
            All HFE Certificated Host Mentors are required to complete a
            specially designed and validated ‘Host Mentor Training Program’.
            Each HFE Host Mentor is trained to understand the basics in helping
            to mentor and support the student’s ‘Conversational English’
            development in the home.
          </p>
          <Header as="h3">Better Outcomes for Students</Header>
          <p>
            We train and assist host families to become HFE Mentors to the
            students. HFE have developed the ‘Host Family English Mentor
            Training Program’ for the homestay host, so they can support the
            student better and more effectively in the development of the
            students’ English language. HFE Mentors also help with the
            enhancement of ‘Soft Skills’, such as motivation and confidence to
            both learn and practice. The attitude and aptitude of the students
            are also developed and identified to ensure students learn
            comfortably and without pressure. In addition to their studies
            during the day, students and their trained HFE Mentor have access to
            an online English language support program for up to 12 weeks.
            Supported by their HFE trained Mentors, this additional learning for
            the student becomes seamless in their understanding and use of
            conversational English.
          </p>
        </Grid.Column>
        <Grid.Column largeScreen="5">
          <Image
            fluid
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555552642/Host%20Family%20English/iStock_30081410_XXLARGE_preview-1024x683.jpg"
          />
          <Header as="h2">The Host Family English Difference</Header>
          <p>
            We believe that combining effective Homestay Host Mentoring with a
            quality language course is a far more effective learning package as
            it builds confidence, motivation and above all, ensures the student
            actually learns the English that they want to learn by living it.
            This is a real life experience and goes back to how we all learned
            our language skills – both at school combined with at home in a
            caring family atmosphere.
          </p>
        </Grid.Column>
      </GridRow>
      <Grid.Row
        columns="3"
        centered
        style={{
          background: "#004165",
          padding: "4em 0em",
        }}
      >
        <GridColumn largeScreen="3" tablet="5">
          <Image
            fluid
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555552462/Host%20Family%20English/agent-card.jpg"
          />
          <Button className={`ui button`} href="/agents">
            Agents
          </Button>
        </GridColumn>
        <GridColumn largeScreen="3" tablet="5">
          <Image
            fluid
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555552440/Host%20Family%20English/school-card.jpg"
          />
          <Button className={`ui button`} href="/education-providers">
            Education Providers
          </Button>
        </GridColumn>
        <GridColumn largeScreen="3" tablet="5">
          <Image
            fluid
            src="https://res.cloudinary.com/mystay-international/image/upload/v1555552419/Host%20Family%20English/host-card.jpg"
          />
          <Button className={`ui button`} href="/homestay-organizations">
            Homestay Organizations
          </Button>
        </GridColumn>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default IndexPage
